import { FC, Fragment, useContext, useState } from "react";
import { Button, Kind, Size } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import "./Reports.scss";
import GenerateReport from "./generateReport/GenerateReport";
import PreviousReports from "./previousReports/PreviousReports";

const BASE_CLASS = "reports";

const Reports: FC = () => {
  const { themeObjState } = useContext(ThemeContext);

  const sidebarButtons = [
    {
      label: "Reports",
      value: "reports",
    },
    {
      label: "Previously generated reports",
      value: "previousReports",
    },
  ];
  const [active, setActive] = useState(sidebarButtons[0]);
  const [selectedReportId, setSelectedReportId] = useState<string>('');

  return (
    <>
      <div className={BASE_CLASS}>
        <div className={`${BASE_CLASS}-sidebar`}>
          {sidebarButtons?.map((item: any, i: number) => (
            <Fragment key={item.value}>
              <Button
                size={Size.m}
                kind={
                  active.value === item.value ? Kind.primaryLoud : Kind.silent
                }
                className={
                  active.value === item.value
                    ? ""
                    : `${BASE_CLASS}-sidebar-option`
                }
                theme={themeObjState}
                label={item.label}
                onClick={() => {
                  if (item === sidebarButtons[0] && active !== sidebarButtons[0]) {
                    setSelectedReportId('');
                  }
                  setActive(item);
                }}
              />
              {i < sidebarButtons.length - 1 && (
                <div
                  className="divider"
                  style={{ marginBottom: "8px", marginTop: "8px" }}
                ></div>
              )}
            </Fragment>
          ))}
        </div>
        <div className={`${BASE_CLASS}-content`}>
          {active.value === 'reports' ? (
            <GenerateReport
              editReportId={selectedReportId}
              viewOnly={false}
            />
          ) : (
            <PreviousReports
              onEditReport={(reportId: string) => {
                setSelectedReportId(reportId);
                setActive(sidebarButtons[0]);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Reports;
