import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, DatePicker, DateRangePicker, Kind, Size } from "@usitsdasdesign/dds-react";
import { Checkbox, BlockScreenMsg, Notify, PopUpModel, Dropdown, AddClauseIcon, CopyClauseIcon, DeleteClauseIcon, CloseIconSvg } from "global";
import { ThemeContext } from "theme/themeContext";
import "./GenerateReport.scss";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import { useDispatch, useSelector } from "react-redux";
import { roleEnum } from "global/constants/Enums";
import { RootState } from "app/store";
import { getAllusers } from "services/orgLevelService";
import ReportService from "services/ReportService";
import {
  InputBox,
  validateRequired,
  validateFileName,
} from "global/components/inputBox/InputBox";
import { FilerIconTeal } from "global";
import { customFormat, getStartOfDay } from "global/utils/DateUtil";
import { ColumnsMap, OperatorsMap } from "../columnsMap";
import ClickAwayListener from "react-click-away-listener";
import { debounce } from "global/utils/debounce";
import TagsInput from "./TagsInput";

interface GenerateReportProps {
  editReportId: string;
  viewOnly: boolean;
}

interface IOption {
  label: string;
  value: string;
}

interface IDropdownProps {
  functionalArea: IOption[];
  department: IOption[];
  subDepartment: IOption[];
}

interface IColumn {
  name: string;
  type: "categories" | "hours" | "userDetails";
  id?: string;
}

interface IClause {
  field: IOption;
  operator: IOption;
  value: string;
  suggestions: string[];
  tags: string[];
}

const BASE_CLASS = "generateReport";
const NO_SUGGESTION_FOUND = "No results found.";
const baseDate = new Date(new Date().setDate(new Date().getDate() - 1));

const GenerateReport: React.FC<GenerateReportProps> = ({
  editReportId,
  viewOnly = false,
}) => {
  const dispatch = useDispatch();
  const { themeObjState } = useContext(ThemeContext);

  const [functionalAreas, setFunctionalAreas] = useState<IOption[]>([]);
  const [departments, setDepartments] = useState<IOption[]>([]);
  const [subDepartments, setSubDepartments] = useState<IOption[]>([]);
  const [locations, setLocations] = useState<IOption[]>([]);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [allData, setAllData] = useState<Record<string, any>>([]);

  const [selectedLocations, setSelectedLocations] = useState<IOption[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<IColumn[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [showGenerateModal, setShowGenerateModal] = useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);

  //advanced search
  const [metaData, setMetaData] = useState<any>({});
  const [allPossibleFields, setAllPossibleFields] = useState<IOption[]>([]);
  const [possibleFields, setPossibleFields] = useState<IOption[]>([]);
  const [showClauseModal, setShowClauseModal] = useState<boolean>(false);
  const [isValidClauses, setIsValidClauses] = useState<boolean>(false);
  const [tempSuggestionIndex, setTempSuggestionIndex] = useState<number | null>(null);
  const clearInputFunctions: (() => void)[] = []; // for clearing value in TagsInput component
  const [clauses, setClauses] = useState<IClause[]>([{
    field: { label: "", value: "" },
    operator: { label: "", value: "" },
    value: "",
    suggestions: [],
    tags: []
  }]);
  const [finalClauses, setFinalClauses] = useState<IClause[]>([]);
  const [checkViewReportWithQuery, setCheckViewReportWithQuery] = useState<boolean>(false);

  const inputRefs = useRef<{ [key: number]: HTMLInputElement | null }>({});
  const [suggestionPositions, setSuggestionPositions] = useState<{ [key: number]: { top: number; left: number } }>({});

  const LoginUserData: IUser = useSelector(
    (state: RootState) => state.globalReducer.loginUser
  );

  const getInitValues = () => ({
    functionalArea: [],
    department: [],
    subDepartment: [],
  });

  const [dropdownValues, setDropdownValues] = useState<IDropdownProps>({
    functionalArea: [],
    department: [],
    subDepartment: [],
  });

  const [formValidity, setFormValidity] = useState({
    "Report name": false,
  });

  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: baseDate,
    endDate: baseDate,
  }); // date range for filtering data

  useEffect(() => {
    if (LoginUserData.entitlements) {
      getData();
    }
  }, [LoginUserData]);

  const editReport = async (reportId: string, tempAllData: any) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });

      await ReportService.getReportById(reportId).then((res: any) => {
        const resData = res.data.data[0];
        setDateRange({
          startDate: new Date(resData.StartDate),
          endDate: new Date(resData.EndDate),
        });

        if (resData.query !== null) {
          const tempQueries = JSON.parse(resData.query);
          setFinalClauses(tempQueries.map((query: any) => ({
            field: {
              label: ColumnsMap[`${query.key}` as keyof typeof ColumnsMap] || query.key,
              value: query.key
            },
            operator: {
              label: ColumnsMap[`${query.operator}` as keyof typeof ColumnsMap] || query.operator,
              value: query.operator
            },
            value: query.value,
            suggestions: [],
            tags: query.operator === 'IN' ? query.value.split(';') : [],
          })));
          if (viewOnly) {
            setCheckViewReportWithQuery(true);
          }
        } else {
          const functionalEntitlements = LoginUserData.role === roleEnum.ENTITLED
            ? LoginUserData.entitlements?.levels.filter(
              (item) => item.functionalArea)
              .map((item) => item.functionalArea)
            : []

          const tempFunctionalAreas = JSON.parse(resData.functionalArea);

          // load dropdown values only in case of:
          // master user, view report, or if entitlements are same as previously selected functional area
          if (LoginUserData.role === roleEnum.MASTER
            || viewOnly
            || (LoginUserData.role === roleEnum.ENTITLED && functionalEntitlements?.length === tempFunctionalAreas.length &&
              functionalEntitlements?.slice().sort().every((value, index) => value === tempFunctionalAreas.slice().sort()[index]))
          ) {
            getDepts(JSON.parse(resData.functionalArea), tempAllData, false);
            getSubDepts(
              JSON.parse(resData.functionalArea),
              JSON.parse(resData.department),
              tempAllData,
              false
            );

            setDropdownValues({
              functionalArea: JSON.parse(resData.functionalArea).map((val: any) => ({
                label: val,
                value: val,
              })),
              department: JSON.parse(resData.department).map((val: any) => ({
                label: val,
                value: val,
              })),
              subDepartment: JSON.parse(resData.subDepartment).map((val: any) => ({
                label: val,
                value: val,
              })),
            });
          }

          setSelectedLocations(JSON.parse(resData.location).map((val: any) => ({
            label: val,
            value: val,
          })));
        }

        const parsedCols = JSON.parse(resData.selection);
        const allColumns: IColumn[] = [
          ...parsedCols.categories.map((category: any) => ({
            name: category.name,
            type: "categories",
            id: category.id,
          })),
          ...parsedCols.hours.map((hour: any) => ({
            name: hour,
            type: "hours",
          })),
          ...parsedCols.userDetails.map((userDetail: any) => ({
            name: userDetail,
            type: "userDetails",
          })),
        ];
        setSelectedColumns(allColumns);
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (ex: any) {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: "Something went wrong. Please try again later.",
          timeout: 3000,
        },
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    }
  };

  const getData = async () => {
    const params = {
      selection: "functionalArea,department,subDepartment,officeLocation",
    };
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      let tempData = {};
      await getAllusers(params).then((res: any) => {
        const resData = res.data.data.record;
        tempData = resData;
        setAllData(resData);
        getLocations(resData);
        getFunctionalAreas(resData);
      });
      getColumns();
      getPossibleFields();
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      if (editReportId) {
        editReport(editReportId, tempData);
      } else {
        setDropdownValues(getInitValues());
        setFinalClauses([]);
      }
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Something went wrong. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };

  const getLocations = (resData: any) => {
    setLocations(
      (
        [
          ...new Set(resData.map((item: IUser | any) => item.officeLocation)),
        ] as string[]
      )
        .sort()
        .map((item) => ({ label: item, value: item }))
    );
  };

  const getFunctionalAreas = (resData: any) => {
    const temp = (
      [
        ...new Set(
          LoginUserData.entitlements?.levels.some(
            (item) => item.functionalArea === "*"
          ) || LoginUserData.role === roleEnum.MASTER
            ? resData.map((item: IUser | any) => item.functionalArea)
            : LoginUserData.entitlements?.levels
              // .filter((item) => item.functionalArea && !item.department && !item.subDepartment)
              .map((item) => item.functionalArea)
        ),
      ] as string[]
    )
      .sort()
      .map((item) => ({ label: item, value: item }));
    setFunctionalAreas(temp);
  };

  const handleFunctionChange = (selected: any) => {
    setFinalClauses([]);
    setDropdownValues((prev) => ({
      ...prev,
      functionalArea: selected,
      department: [],
      subDepartment: [],
    }));

    if (selected.length === 1) {
      getDepts(
        selected.map((item: IOption) => item.value),
        allData
      );
    } else {
      getDepts([], allData); //check
      setDepartments([]);
      setSubDepartments([]);
    }
  };

  const handleDepartmentChange = (selected: any) => {
    setDropdownValues((prev) => ({
      ...prev,
      department: selected,
      subDepartment: [],
    }));

    if (selected.length === 1) {
      getSubDepts(
        dropdownValues?.functionalArea.map((item) => item.value),
        selected.map((item: IOption) => item.value),
        allData
      );
    } else {
      setSubDepartments([]);
    }
  };

  const handleSubDepartmentChange = (selected: any) => {
    setDropdownValues((prev) => ({
      ...prev,
      subDepartment: selected,
    }));
  };

  const getDepts = (
    functionalAreaStrings: string[],
    resData: any,
    isUpdata: boolean = true
  ) => {
    const tempData = resData.filter((item: any) =>
      functionalAreaStrings.includes(item.functionalArea)
    );

    setSubDepartments([]);
    if (tempData.length === 0) {
      setDepartments([]);
      return;
    }

    const tempEn = LoginUserData.entitlements?.levels
      .filter((item) => functionalAreaStrings.includes(item.functionalArea))
      .map((item: { department: string }) => item.department) as string[];

    const tempSubDepts = tempData.map(
      (item: IUser | any) => item.department
    ) as string[];

    const ttDepts = (
      [
        ...new Set(
          tempEn?.some((item: string) => item === "") ||
            LoginUserData.role === roleEnum.MASTER
            ? tempSubDepts
            : tempEn.filter((item) => tempSubDepts.includes(item))
        ),
      ] as string[]
    )
      .sort()
      .map((item) => ({ label: item, value: item }));

    setDepartments(ttDepts);

    if (isUpdata) {
      setDropdownValues((prev) => ({
        ...prev,
        functionalArea: functionalAreaStrings.map((item) => ({
          label: item,
          value: item,
        })),
        department: ttDepts,
        subDepartment: [],
      }));
    }
  };

  const getSubDepts = (
    functionalAreaStrings: string[],
    departmentStrings: string[],
    resData: any,
    isUpdata: boolean = true
  ) => {
    const tempData = resData.filter(
      (item: IUser | any) =>
        functionalAreaStrings.includes(item.functionalArea) &&
        departmentStrings.includes(item.department) &&
        item.subDepartment
    );

    if (tempData.length === 0) {
      setSubDepartments([]);
      return;
    }
    const tempEn = LoginUserData.entitlements?.levels
      .filter((item) => functionalAreaStrings.includes(item.functionalArea))
      .map((item: { subDepartment: string }) => item.subDepartment) as string[];

    const tempSubDepts = tempData.map(
      (item: IUser | any) => item.subDepartment
    ) as string[];

    const tttSubdepts = (
      [
        ...new Set(
          tempEn?.some((item) => item === "") ||
            LoginUserData.role === roleEnum.MASTER
            ? tempSubDepts
            : tempEn.filter((item) => tempSubDepts.includes(item))
        ),
      ] as string[]
    )
      .sort()
      .map((item) => ({ label: item, value: item }));

    setSubDepartments(tttSubdepts);
    isUpdata &&
      setDropdownValues((prev) => ({
        ...prev,
        functionalArea: functionalAreaStrings.map((item) => ({
          label: item,
          value: item,
        })),
        department: departmentStrings.map((item) => ({
          label: item,
          value: item,
        })),
        subDepartment: tttSubdepts,
      }));
  };

  const getColumns = async () => {
    const params = {
      columns: true,
    };
    try {
      await ReportService.getAll(params).then((res: any) => {
        const resData = res.data.data;
        const allColumns: IColumn[] = [
          ...resData.categories.map((category: any) => ({
            name: category.name,
            type: "categories",
            id: category.id,
          })),
          ...resData.hours.map((hour: any) => ({
            name: hour,
            type: "hours",
          })),
          ...resData.userDetails.map((userDetail: any) => ({
            name: userDetail,
            type: "userDetails",
          })),
        ];

        setColumns(allColumns);
        if (!editReportId) {
          setSelectedColumns(allColumns);
        }
      });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Something went wrong. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };

  const getPossibleFields = async () => {
    const params = {
      advancedSearch: true,
    };
    try {
      await ReportService.getAll(params).then((res: any) => {
        const resData = res.data.data;
        const parsedData: { [key: string]: { operators: string[]; values: string[] } } = {};

        const transformedArray = resData.map((obj: any) => {
          const key = Object.keys(obj)[0];
          parsedData[key] = obj[key];
          return {
            label: ColumnsMap[`${key}` as keyof typeof ColumnsMap] || key,
            value: key
          };
        });

        setMetaData(parsedData);
        setAllPossibleFields(transformedArray);
        setPossibleFields(transformedArray);
      });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Something went wrong. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };

  useEffect(() => {
    const fieldsWithIN = clauses.filter(
      (clause) => metaData[clause.field.value]?.operators.includes('IN')
    ).map(
      (clause) => clause.field.value
    );

    const newPossibleFields = allPossibleFields.filter(
      (field) => !fieldsWithIN.includes(field.value)
    )

    setPossibleFields(newPossibleFields);
  }, [clauses]);

  const handleFieldChange = (index: number, value: any) => {
    const newClauses = clauses.map((clause, i) => {
      if (i === index) {
        return {
          ...clause,
          field: { label: value.label, value: value.value },
          operator: { label: '', value: '' },
          value: '',
          suggestions: [],
          tags: []
        };
      }
      return clause;
    });
    setClauses(newClauses);
  };

  const handleOperatorChange = (index: number, value: any) => {
    const newClauses = clauses.map((clause, i) => {
      if (i === index) {
        return {
          ...clause,
          operator: { label: value.label, value: value.value }
        };
      }
      return clause;
    });
    setClauses(newClauses);
  };

  const selectedTags = (index: number, tags: string[], clauses: IClause[]) => {
    const newClauses = clauses.map((clause, i) => {
      if (i === index) {
        return {
          ...clause,
          value: tags.join(';'),
          suggestions: [],
          tags: tags
        };
      }
      return clause;
    });
    setClauses(newClauses);
  };

  const currentInputValue = (index: number, value: string) => {
    if (value.length >= 3) fetchSuggestions(index, value, clauses);
  };

  const debouncedFetchSuggestions = useCallback(
    debounce((index: number, inputValue: string, clauses: IClause[]) => {
      fetchSuggestions(index, inputValue, clauses);
    }, 500),
    []
  );

  const handleValueChange = (index: number, value: string) => {
    const newClauses = clauses.map((clause, i) => {
      if (i === index) {
        return { ...clause, value: value };
      }
      return clause;
    });
    setClauses(newClauses);
    debouncedFetchSuggestions(index, value, newClauses);
  };

  const fetchSuggestions = async (index: number, inputValue: string, clauses: IClause[]) => {
    const params = {
      suggestionSearch: true,
      key: clauses[index].field.value,
      term: inputValue
    };

    if (inputValue.length >= 3) {
      try {
        await ReportService.getAll(params).then((res: any) => {
          const resData = res.data.data;
          if (resData.length > 0) {
            handleSuggestionChange(index, resData, clauses);
          } else {
            const newArray = [...resData];
            newArray.push(NO_SUGGESTION_FOUND);
            handleSuggestionChange(index, newArray, clauses);
          }
        });
      } catch (error) {
        console.error('Error fetching suggestions: ', error);
      }
    } else {
      handleSuggestionChange(index, [], clauses);
    }
  };

  const handleSuggestionChange = (index: number, array: string[], clauses: IClause[]) => {
    const newClauses = clauses.map((clause, i) => {
      if (i === index) {
        return {
          ...clause,
          suggestions: array
        };
      }
      return clause;
    });
    setClauses(newClauses);
  };

  const handleSuggestionSelect = (index: number, suggestion: string) => {
    if (clauses[index].operator.value !== 'IN') {
      const newClauses = clauses.map((clause, i) => {
        if (i === index) {
          return {
            ...clause,
            value: suggestion,
            suggestions: []
          };
        }
        return clause;
      });
      setClauses(newClauses);
    } else {
      const newTags = [...clauses[index].tags];
      newTags.push(suggestion);
      const newClauses = clauses.map((clause, i) => {
        if (i === index) {
          return {
            ...clause,
            value: newTags.join(';'),
            suggestions: [],
            tags: newTags
          };
        }
        return clause;
      });

      setClauses(newClauses);
      clearInputFunctions[index]();
    }
    setTempSuggestionIndex(null);
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent, value: string) => {
    const suggestionsArray = clauses[index].suggestions;

    if (event.key === 'ArrowDown') {
      if (tempSuggestionIndex === null) {
        setTempSuggestionIndex(0);
      } else if (tempSuggestionIndex < clauses[index].suggestions.length - 1) {
        setTempSuggestionIndex(tempSuggestionIndex + 1);
      } else {
        setTempSuggestionIndex(null);
      }
    } else if (event.key === 'ArrowUp') {
      if (tempSuggestionIndex === null) {
        setTempSuggestionIndex(clauses[index].suggestions.length - 1);
      } else if (tempSuggestionIndex > 0) {
        setTempSuggestionIndex(tempSuggestionIndex - 1);
      } else {
        setTempSuggestionIndex(null);
      }
    } else if (event.key === 'Enter') {
      if (suggestionsArray.length < 1 || value.length < 1) {
        return;
      }

      if (tempSuggestionIndex !== null
        && suggestionsArray[tempSuggestionIndex].length > 0
        && suggestionsArray[tempSuggestionIndex] !== NO_SUGGESTION_FOUND
      ) {
        handleSuggestionSelect(index, suggestionsArray[tempSuggestionIndex]);
      } else {
        handleSuggestionSelect(index, value);
      }
    }
  };

  const handleClickAway = (index: number) => {
    handleSuggestionChange(index, [], clauses);
    setTempSuggestionIndex(null);
  };

  const getPlaceholderValues = () => {
    if (dropdownValues.functionalArea.length < 1) {
      return "Select";
    } else if (dropdownValues.functionalArea.length > 1) {
      return "All";
    }

    if (dropdownValues.department.length < 1) {
      return "Select";
    } else if (dropdownValues.department.length > 1) {
      return "All";
    }

    return "";
  };

  const validateOptions = () => {
    const errorMessage =
      selectedLocations.length === 0 && finalClauses.length === 0
        ? "Please select at least one location to generate the report."
        : selectedColumns.length === 0
          ? "Please select at least one column to generate the report."
          : "";

    if (errorMessage.length > 0) {
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: errorMessage,
          timeout: 3000,
        },
      });
      return false;
    }
    return true;
  };

  const generateReport = async () => {
    const selectedCols = {
      categories: [] as {
        id: string;
        name: string;
      }[],
      hours: [] as string[],
      userDetails: [] as string[],
    };

    selectedColumns.forEach((col) => {
      if (col.type === "categories" && col.id) {
        selectedCols.categories.push({
          id: col.id,
          name: col.name,
        });
      } else if (col.type === "hours") {
        selectedCols.hours.push(col.name);
      } else if (col.type === "userDetails") {
        selectedCols.userDetails.push(col.name);
      }
    });

    const apiBodyQueries = finalClauses.map((clause) => {
      return {
        key: clause.field.value,
        operator: clause.operator.value,
        value: clause.value
      }
    });

    const data = finalClauses.length > 0 ? {
      name: fileName,
      query: apiBodyQueries,
      startDate: getStartOfDay(dateRange.startDate),
      endDate: getStartOfDay(dateRange.endDate),
      selection: selectedCols,
    } : {
      name: fileName,
      functionalArea: dropdownValues.functionalArea.map((val) => val.value),
      department: dropdownValues.department.map((val) => val.value),
      subDepartment: dropdownValues.subDepartment.map((val) => val.value),
      location: selectedLocations.map((val) => val.value),
      startDate: getStartOfDay(dateRange.startDate),
      endDate: getStartOfDay(dateRange.endDate),
      selection: selectedCols,
    };

    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await ReportService.generateReport(data).then(() => {
        dispatch({
          type: CALL_NOTIFY,
          payload: {
            type: "SUCCESS",
            msg: `Report generation started.`,
            timeout: 3000,
          },
        });
      });
      setShowEmailModal(true);
      resetPage();
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (error: any) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg:
            error.response.data.message ||
            `Error exporting data. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };

  /**
   * method to get HTML for displaying view only items with checkboxes in view report
   * @param displayList array of objects which is to be displayed (should have label property)
   * @param showAllIfEmpty if array is empty, 'All' button will be shown
   * @returns HTML- checkbox (ticked) and label
   */
  const displaySelectedItems = (displayList: any[], showAllIfEmpty: boolean = false) => {
    return (
      <>
        {displayList.length ? (
          <>
            <div className={`${BASE_CLASS}-section-checkbox-list`}>
              {displayList.map((item, i) => (
                <div
                  className={`${BASE_CLASS}-section-checkbox-viewOnly`}
                  key={i}
                >
                  <Checkbox
                    value={true}
                    disabled={true}
                    onChange={() => { }}
                  />
                  <div className={`${displayList.length > 1 ? 'ellipsis' : ''}`}>
                    {item.label}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            {showAllIfEmpty ? (
              <div className={`${BASE_CLASS}-section-checkbox-single`}>
                <Checkbox
                  value={true}
                  disabled={true}
                  onChange={() => { }}
                />
                <div className="ellipsis">All</div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  };

  const handleUserInputChange = (fieldName: any, isValid: any) => {
    setFormValidity((prevState) => ({
      ...prevState,
      [fieldName]: isValid,
    }));
  };

  const isValidFields = Object.values(formValidity).every((valid) => valid);

  const resetPage = () => {
    setDateRange({
      startDate: baseDate,
      endDate: baseDate,
    });
    editReportId = "";
    setDropdownValues(getInitValues());
    setSelectedLocations([]);
    setSelectedColumns(columns);
    setFunctionalAreas([]);
    setDepartments([]);
    setSubDepartments([]);
    getData();
    setFinalClauses([]);
  };

  const disableResetButton =
    finalClauses.length === 0 &&
    getStartOfDay(dateRange.startDate) === getStartOfDay(baseDate) &&
    getStartOfDay(dateRange.endDate) === getStartOfDay(baseDate) &&
    JSON.stringify(dropdownValues) === JSON.stringify(getInitValues()) &&
    selectedLocations.length === 0 &&
    selectedColumns.length === columns.length;

  const handleAddClause = () => {
    setClauses([...clauses, {
      field: { label: "", value: "" },
      operator: { label: "", value: "" },
      value: "",
      suggestions: [],
      tags: []
    }]);
  };

  const handleDeleteClause = (index: number) => {
    if (clauses.length > 1) {
      const newClauses = clauses.filter((_, i) => i !== index);
      setClauses(newClauses);
    }
  };

  const handleDuplicateClauses = (index: number) => {
    const currentClause = { ...clauses[index] };
    const newClauses = [
      ...clauses.slice(0, index + 1),
      currentClause,
      ...clauses.slice(index + 1),
    ];
    setClauses(newClauses);
  };

  const resetClauses = () => {
    setClauses([{
      field: { label: '', value: '' },
      operator: { label: '', value: '' },
      value: '',
      suggestions: [],
      tags: []
    }]);
  };

  useEffect(() => {
    setIsValidClauses(checkValidity(clauses));
  }, [clauses]);

  const checkValidity = (clauses: IClause[]) => {
    let result = true;
    clauses.forEach((clause) => {
      if (clause.field.value.length === 0 || clause.operator.value.length === 0 || clause.value.length === 0) {
        result = false;
        return;
      }
    });
    return result;
  };

  const getAdvancedSearchHTML = (viewReport: boolean = false) => {
    return (
      <div>
        {!viewReport && (
          <div
            className={`${BASE_CLASS}-advancedSearch`}
            onClick={() => {
              if (finalClauses.length > 0) {
                setClauses(finalClauses);
              } else {
                setClauses([{
                  field: { label: "", value: "" },
                  operator: { label: "", value: "" },
                  value: "",
                  suggestions: [],
                  tags: []
                }])
              }
              setShowClauseModal(true);
            }}
          >
            <img
              src={FilerIconTeal}
              alt="Filter"
            />
            Advanced search
            {finalClauses.length > 0 && (
              <div className={`${BASE_CLASS}-advancedSearch-tag`}>
                {finalClauses.length}
              </div>
            )}
          </div>
        )}

        <div className={`${BASE_CLASS}-chips`}>
          {finalClauses.map((clause, index) => (
            <div key={index} className={`${BASE_CLASS}-chips-group`}>
              {index !== 0 && <span>&</span>}
              <div className={`${BASE_CLASS}-chips-chip`}>
                {clause.field.label} {clause.operator.value}
                {clause.field.value === 'doj' ? (
                  <> {customFormat(parseInt(clause.value), "DDMMYYYY")}</>
                ) : (
                  <> {clause.value}</>
                )}
                {!viewReport && (
                  <CloseIconSvg
                    className="cursor"
                    onClick={() => setFinalClauses(finalClauses.filter((_, ind) => ind !== index))}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  // to show the suggestions menu below corresponding input tag, even with scroll
  const calculateSuggestionPosition = (index: number) => {
    const input = inputRefs.current[index];
    if (input) {
      const rect = input.getBoundingClientRect();
      console.log(rect);
      setSuggestionPositions((prevPositions) => ({
        ...prevPositions,
        [index]: {
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
        },
      }));
    }
  };

  useEffect(() => {
    clauses.forEach((_, index) => {
      calculateSuggestionPosition(index);
    });
  }, [clauses]);

  return (
    <>
      <div className={`${BASE_CLASS} ${viewOnly ? 'noPadding' : ''}`}>
        {!viewOnly && (
          <div className={`${BASE_CLASS}-header dds-h5`}>
            Reports
          </div>
        )}

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-section-header dds-h6`}>
            Date range
          </div>
          <div className={`${BASE_CLASS}-daterange`}>
            <DateRangePicker
              size={Size.l}
              theme={themeObjState}
              maxDate={baseDate}
              labelPosition="external"
              label={"Start and end date"}
              disabledDates={{ after: new Date(), dates: [new Date()] }}
              isManualInput={false}
              format="DD/MM/YYYY"
              icon="calendar__s__stroke"
              customClass="daterange"
              isRequired={true}
              stickerOptions={{
                isDisabled: viewOnly ? true : false
              }}
              value={[dateRange.startDate, dateRange.endDate]}
              dateRangeSelected={(data: string[] | Date[] | null) => {
                if (data && data.length === 2) {
                  setDateRange({
                    startDate: new Date(data[0]),
                    endDate: new Date(data[1]),
                  });
                }
              }}
            />
          </div>
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-section-header dds-h6`}>
            {viewOnly ? (
              <>
                {checkViewReportWithQuery ? (
                  <div className={`${BASE_CLASS}-viewAdvancedSearch`}>
                    Advanced search
                    {finalClauses.length > 0 && (
                      <div className={`${BASE_CLASS}-advancedSearch-tag`}>
                        {finalClauses.length}
                      </div>
                    )}
                  </div>
                ) : (
                  <>Authorisation(s)</>
                )}
              </>
            ) : (
              <>Select filters</>
            )}
          </div>

          {viewOnly ? (
            <div>
              {checkViewReportWithQuery ? (
                <>
                  {getAdvancedSearchHTML(true)}
                  <div className={`${BASE_CLASS}-divider`} />
                </>
              ) : (
                <>
                  <div>
                    <div className={`${BASE_CLASS}-section dds-h6`} style={{ fontSize: '14px', marginBottom: '1vh' }}>
                      Function
                    </div>
                    <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
                      {displaySelectedItems(dropdownValues.functionalArea)}
                    </div>
                  </div>

                  <div className={`${BASE_CLASS}-divider`} />

                  <div>
                    <div className={`${BASE_CLASS}-section dds-h6`} style={{ fontSize: '14px', marginBottom: '1vh' }}>
                      Department
                    </div>
                    <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
                      {dropdownValues.department.length === subDepartments.length
                        && dropdownValues.department.length > 1 ? (
                        <>
                          {displaySelectedItems([], true)}
                        </>
                      ) : (
                        <>
                          {displaySelectedItems(dropdownValues.department, true)}
                        </>
                      )}
                    </div>
                  </div>

                  <div className={`${BASE_CLASS}-divider`} />

                  <div>
                    <div className={`${BASE_CLASS}-section dds-h6`} style={{ fontSize: '14px', marginBottom: '1vh' }}>
                      Sub department
                    </div>
                    <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
                      {dropdownValues.subDepartment.length === subDepartments.length
                        && dropdownValues.subDepartment.length > 1 ? (
                        <>
                          {displaySelectedItems([], true)}
                        </>
                      ) : (
                        <>
                          {displaySelectedItems(dropdownValues.subDepartment, true)}
                        </>
                      )}
                    </div>
                  </div>

                  <div className={`${BASE_CLASS}-divider`} />

                  <div>
                    <div className={`${BASE_CLASS}-section dds-h6`} style={{ fontSize: '14px', marginBottom: '1vh' }}>
                      Location
                    </div>
                    <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
                      {displaySelectedItems(selectedLocations)}
                    </div>
                  </div>

                  <div className={`${BASE_CLASS}-divider`} />
                </>
              )}
            </div>
          ) : (
            <>
              <div className={`dropdownContainer`}>
                <Dropdown
                  className="dropdown"
                  viewOnly={viewOnly}
                  defaultValue={dropdownValues.functionalArea}
                  labelText={"Function"}
                  isRequired={false}
                  addLabel={true}
                  showValue={true}
                  isSearchBox={false}
                  onchange={handleFunctionChange}
                  placeholder={"Select"}
                  selectAllText="All"
                  options={functionalAreas}
                  isMulti={true}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                />

                <Dropdown
                  className="dropdown"
                  defaultValue={dropdownValues.department}
                  viewOnly={viewOnly}
                  labelText={"Department"}
                  addLabel={true}
                  showValue={true}
                  onchange={handleDepartmentChange}
                  placeholder={getPlaceholderValues()}
                  selectAllText="All"
                  isMulti={true}
                  disabled={dropdownValues.functionalArea.length !== 1}
                  options={departments}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                />

                <Dropdown
                  className="dropdown"
                  defaultValue={dropdownValues.subDepartment}
                  viewOnly={viewOnly}
                  labelText={"Sub-department"}
                  addLabel={true}
                  showValue={true}
                  onchange={handleSubDepartmentChange}
                  placeholder={getPlaceholderValues()}
                  selectAllText="All"
                  disabled={
                    dropdownValues.functionalArea.length !== 1
                    || dropdownValues.department.length > 1
                  }
                  options={subDepartments}
                  isMulti={true}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                />

                <Dropdown
                  className="dropdown"
                  defaultValue={selectedLocations}
                  labelText={"Location"}
                  addLabel={true}
                  showValue={true}
                  isSearchBox={false}
                  onchange={(selected: any) => {
                    setFinalClauses([]);
                    setSelectedLocations(selected);
                  }}
                  placeholder={"Select"}
                  selectAllText="All"
                  options={locations}
                  isMulti={true}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                />
              </div>

              <div className={`${BASE_CLASS}-separator dds-label`}>
                <div className={`${BASE_CLASS}-divider`} />
                Or
                <div className={`${BASE_CLASS}-divider`} />
              </div>

              {getAdvancedSearchHTML()}
            </>
          )}
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-section-header dds-h6`}>
            Show columns
          </div>
          <div className={`${BASE_CLASS}-section-checkbox dds-h7`}>
            {viewOnly ? (
              <>
                {displaySelectedItems(selectedColumns.map(item => ({
                  label: ColumnsMap[`${item.name}` as keyof typeof ColumnsMap] || item.name
                })))}
              </>
            ) : (
              <>
                {columns.length ? (
                  <div className={`${BASE_CLASS}-section-checkbox-list`}>
                    {columns.map((item, i) => (
                      <div
                        className={`${BASE_CLASS}-section-checkbox-single`}
                        key={i}
                      >
                        <Checkbox
                          value={
                            selectedColumns.filter(
                              (col: any) =>
                                col.name === item.name &&
                                col.type === item.type &&
                                col.id === item.id
                            ).length > 0
                          }
                          onChange={() => {
                            if (!viewOnly) {
                              const isAlreadySelected =
                                selectedColumns.filter(
                                  (col: any) =>
                                    col.name === item.name &&
                                    col.type === item.type &&
                                    col.id === item.id
                                ).length > 0;

                              if (isAlreadySelected) {
                                setSelectedColumns(
                                  selectedColumns.filter(
                                    (col: any) =>
                                      !(
                                        col.name === item.name &&
                                        col.type === item.type &&
                                        col.id === item.id
                                      )
                                  )
                                );
                              } else {
                                setSelectedColumns((prev) => [...prev, item]);
                              }
                            }
                          }}
                        />
                        <div className="ellipsis">
                          {ColumnsMap[`${item.name}` as keyof typeof ColumnsMap] || item.name}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>

        {!viewOnly && (
          <div className="footer">
            <Button
              size={Size.m}
              className="generate-button"
              kind={Kind.primaryLoud}
              theme={themeObjState}
              label="Generate"
              isDisabled={!(dropdownValues.functionalArea.length > 0 || finalClauses.length > 0)}
              onClick={() => {
                if (validateOptions()) {
                  setShowGenerateModal(true);
                }
              }}
            />
            <Button
              size={Size.m}
              className="reset-button"
              kind={Kind.primary}
              theme={themeObjState}
              label="Reset"
              isDisabled={disableResetButton}
              onClick={resetPage}
            />
          </div>
        )}
      </div>

      {showGenerateModal && (
        <PopUpModel
          saveTitle={"Generate"}
          onClickClose={() => {
            setShowGenerateModal(false);
            setFileName("");
            setFormValidity({
              "Report name": false,
            });
          }}
          onSaveClick={() => {
            generateReport();
            setFormValidity({
              "Report name": false,
            });
          }}
          disabledSave={!isValidFields}
          Title={"Name the report"}
        >
          <div className={`${BASE_CLASS}-popup-body`}>
            <InputBox
              value={fileName}
              mainClassName="width-100"
              labelText={"Report name"}
              maxLength={64}
              minLength={5}
              errorText="name"
              addLabel={true}
              placeholder="Type name here"
              suffix=".xlsx"
              validators={[validateRequired, validateFileName]}
              onInputChange={handleUserInputChange}
              onChange={(value: any) => setFileName(value)}
            />
          </div>
        </PopUpModel>
      )}

      {showEmailModal && (
        <PopUpModel
          Title="Generating"
          onClickClose={() => {
            setShowEmailModal(false);
            setShowGenerateModal(false);
            setFileName("");
          }}
          onSaveClick={async () => {
            setShowEmailModal(false);
            setShowGenerateModal(false);
            setFileName("");
          }}
          saveTitle="Okay"
          closeTitle="Cancel"
          closeButtonClass="cancelColor"
        >
          <p style={{ fontSize: "14px" }}>
            {`Due to the large size of the data, generating the file may take some time. You will receive an email once it is complete.`}
          </p>
        </PopUpModel>
      )}

      {showClauseModal && (
        <PopUpModel
          Title="Advanced search"
          onClickClose={() => {
            setShowClauseModal(false);
          }}
          onSaveClick={() => {
            setFinalClauses(clauses);
            setDropdownValues(getInitValues());
            setSelectedLocations([]);
            setShowClauseModal(false);
          }}
          onResetClick={() => resetClauses()}
          disabledSave={!isValidClauses}
          saveTitle="Apply"
          closeTitle="Close"
          popupModelMainStyle={{ width: "74%", maxHeight: "90vh" }}
        >
          <div style={{ maxHeight: "64vh", padding: "0 15px 22px 0", overflow: "auto" }}>
            <div className={`${BASE_CLASS}-heading`}>
              <div className={`${BASE_CLASS}-heading-AND`}>AND</div>
              <div className={`${BASE_CLASS}-heading-value`}>Select fields</div>
              <div className={`${BASE_CLASS}-heading-value`}>Conditions</div>
              <div className={`${BASE_CLASS}-heading-value`}>Value</div>
              <div className={`${BASE_CLASS}-heading-action`}>
                <div>
                  <img src={AddClauseIcon} />
                </div>
                <div>
                  <img src={CopyClauseIcon} />
                </div>
                <div>
                  <img src={DeleteClauseIcon} />
                </div>
              </div>
            </div>

            {clauses.map((clause, index) => (
              <div key={index} className={`${BASE_CLASS}-clause`}>
                <div
                  className={`${BASE_CLASS}-clause-AND `}
                  style={{ visibility: index === 0 ? 'visible' : 'hidden' }}
                >
                  AND
                </div>
                <Dropdown
                  className={`${BASE_CLASS}-clause-dropdown`}
                  defaultValue={clause.field}
                  isRequired={false}
                  showValue={true}
                  isSearchBox={true}
                  isSearchable={true}
                  onchange={(item: any) => handleFieldChange(index, item)}
                  placeholder={''}
                  options={possibleFields}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  dropdownIndicatorStyle={{ visibility: "hidden", marginLeft: "-8px" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400, flexWrap: "nowrap" }}
                  dropDownCmpStyle={{ position: "relative" }}
                />
                <Dropdown
                  className={`${BASE_CLASS}-clause-dropdown`}
                  defaultValue={clause.operator}
                  isRequired={false}
                  showValue={true}
                  isSearchBox={true}
                  isSearchable={true}
                  onchange={(item: any) => handleOperatorChange(index, item)}
                  placeholder={''}
                  selectAllText="All"
                  options={metaData[clause.field.value]?.operators.map((item: string) => ({
                    label: OperatorsMap[`${item}` as keyof typeof OperatorsMap] || item,
                    value: item
                  })) || []}
                  disabled={!metaData[clause.field.value]}
                  controlShouldRenderValue={false}
                  isClearable={false}
                  dropdownIndicatorStyle={{ visibility: "hidden", marginLeft: "-8px" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400, flexWrap: "nowrap" }}
                  dropDownCmpStyle={{ position: "relative" }}
                />
                {clause.field.value === 'doj' && clause.operator.value.length > 0 ? (
                  <>
                    <DatePicker
                      customClass="datePicker"
                      placeholder='Select date'
                      icon='calendar__s__stroke'
                      format='DD/MM/YYYY'
                      value={Number.isNaN(parseInt(clause.value)) ? undefined : new Date(parseInt(clause.value))}
                      disabledDates={{ after: new Date(), dates: [new Date()] }}
                      dateSelected={(date) => {
                        handleValueChange(index, getStartOfDay(date).toString());
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div className={`${BASE_CLASS}-clause-input ${!clause?.operator.value ? 'disabled' : ''}`}>
                      {clause.field.value?.length > 0 && clause.operator?.value === 'IN' ? (
                        <div style={{ width: '18vw', height: '36px' }}>
                          <TagsInput
                            tags={[...clause.tags]}
                            selectedTags={(tags) => selectedTags(index, tags, clauses)}
                            currentInputValue={(val) => currentInputValue(index, val)}
                            clearInput={(clear) => {
                              clearInputFunctions[index] = clear;
                            }}
                          />
                        </div>
                      ) : (
                        <input
                          ref={(input) => (inputRefs.current[index] = input)}
                          className={`${BASE_CLASS}-clause-input-box`}
                          type="text"
                          value={clause.value}
                          onChange={(e) => handleValueChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(index, e, clause.value)}
                          onFocus={() => {
                            if (clause.value) {
                              fetchSuggestions(index, clause.value, clauses);
                            }
                          }}
                        />
                      )}
                      {clause.suggestions?.length > 0 && (
                        <ClickAwayListener onClickAway={() => handleClickAway(index)}>
                          <div
                            className={`${BASE_CLASS}-clause-suggestion`}
                            onClick={(e) => e.stopPropagation()}
                            style={{ top: suggestionPositions[index]?.top }}
                          >
                            {clause.suggestions.map((suggestion, suggestionIndex) => (
                              <div
                                key={suggestion}
                                style={{
                                  padding: '5px',
                                  cursor: suggestion === NO_SUGGESTION_FOUND ? 'not-allowed' : 'pointer',
                                  backgroundColor: tempSuggestionIndex === suggestionIndex ? '#F1F1F0' : 'white',
                                }}
                                onClick={() => {
                                  if (suggestion !== NO_SUGGESTION_FOUND) {
                                    handleSuggestionSelect(index, suggestion);
                                  }
                                }}
                                onMouseEnter={() => setTempSuggestionIndex(suggestionIndex)}
                                onMouseLeave={() => setTempSuggestionIndex(null)}
                              >
                                {suggestion}
                              </div>
                            ))}
                          </div>
                        </ClickAwayListener>
                      )}
                    </div>
                  </>
                )}
                <div className={`${BASE_CLASS}-clause-action`}>
                  <div>
                    <img
                      className={"cursor"}
                      src={AddClauseIcon}
                      onClick={handleAddClause}
                    />
                  </div>
                  <div>
                    <img
                      className={metaData[clause.field.value]?.operators.includes('IN') ? "disabled" : "cursor"}
                      src={CopyClauseIcon}
                      onClick={() => handleDuplicateClauses(index)}
                    />
                  </div>
                  <div>
                    <img
                      className={clauses.length <= 1 ? "disabled" : "cursor"}
                      src={DeleteClauseIcon}
                      onClick={() => handleDeleteClause(index)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </PopUpModel>
      )}

      <Notify />
      <BlockScreenMsg />
    </>
  );
};
export default GenerateReport;
